var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-title',{attrs:{"title":"Счета | KEL.KZ"}}),_c('Header',{attrs:{"routes":_vm.headerRoutes.defaultsoutes}}),_c('div',{staticClass:"container py-4"},[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-12"},[_c('Accounts',{attrs:{"Agent":_vm.Agent,"changeBalanceAccount":_vm.getInvoices}})],1)]),_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(!_vm.balanceAccountId)?_c('div',[_c('DxLoadIndicator',{attrs:{"id":"medium-indicator","height":20,"width":20}}),_vm._v(" Загрузка ")],1):_vm._e(),(_vm.balanceAccountId)?_c('div',[_c('DxDataGrid',{ref:_vm.dataGridRefKey,attrs:{"id":"gridContainerInvoices","data-source":_vm.storeInvoices,"columns":_vm.columnsInvoices,"show-borders":true,"row-alternation-enabled":true,"show-row-lines":true,"customize-columns":_vm.customizeColumnsInvoices,"paging":{pageSize: _vm.pageSize, enabled: true},"pager":_vm.pager,"remote-operations":true,"filter-row":{visible: true}},on:{"row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"number-cell-template",fn:function(ref){
var data = ref.data;
return [_c('div',[(_vm.$mq == 'sm' || _vm.$mq == 'md' || _vm.$mq == 'lg')?_c('div',{domProps:{"innerHTML":_vm._s(_vm.getStatusNumber(data.data.status, data.data.number))}}):_vm._e(),(_vm.$mq != 'sm' && _vm.$mq != 'md' && _vm.$mq != 'lg')?_c('div',[_vm._v(_vm._s(data.data.number))]):_vm._e()])]}},{key:"chart-cell-template",fn:function(ref){
var data = ref.data;
return [_c('div',[(_vm.$mq == 'sm' || _vm.$mq == 'md' || _vm.$mq == 'lg')?_c('span',[_vm._v(_vm._s(_vm._f("formatMoney2")(data.data.postingAmount)))]):_vm._e(),(_vm.$mq != 'sm' && _vm.$mq != 'md' && _vm.$mq != 'lg')?_c('span',{staticClass:"tooltip-lable"},[_vm._v(_vm._s(_vm._f("formatMoney2")(data.data.postingAmount)))]):_vm._e(),(_vm.$mq != 'sm' && _vm.$mq != 'md' && _vm.$mq != 'lg')?_c('DxBullet',{staticClass:"bullet",attrs:{"color":'#5ebd5e',"start-scale-value":0,"end-scale-value":data.data.amount,"target":data.data.amount,"value":data.data.postingAmount,"show-zero-level":true},scopedSlots:_vm._u([{key:"tooltipTemplate",fn:function(ref){
var data = ref.data;
return [_c('div',[_c('b',[_vm._v("Сумма счета:")]),_vm._v(" "+_vm._s(_vm._f("formatMoney2")(data.target))),_c('br'),_c('b',{staticClass:"text-success"},[_vm._v("Оплаченная сумма:")]),_vm._v(" "+_vm._s(_vm._f("formatMoney2")(data.value))),_c('br'),_c('b',{staticClass:"text-danger"},[_vm._v("Не оплаченная сумма:")]),_vm._v(" "+_vm._s(_vm._f("formatMoney2")((data.target-data.value)))+" ")])]}}],null,true)},[_c('DxTooltip',{attrs:{"content-template":"tooltipTemplate"}})],1):_vm._e()],1)]}},{key:"masterDetailTemplate",fn:function(ref){
var data = ref.data;
return [_c('Invoice',{attrs:{"invoiceId":data.key,"agent":_vm.Agent,"balanceAccountId":_vm.balanceAccountId}})]}}],null,false,2362483235)},[_c('DxSelection',{attrs:{"mode":"single"}}),_c('DxHeaderFilter',{attrs:{"visible":true}}),_c('DxMasterDetail',{attrs:{"enabled":true,"template":"masterDetailTemplate"}})],1)],1):_vm._e()])])]),_c('Footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"long-title text-center mb-3"},[_c('h1',{staticClass:"page-title"},[_vm._v("Счета")])])}]

export { render, staticRenderFns }