<template>
    <div>
        <vue-title title="Счета | KEL.KZ"></vue-title>
        <Header :routes="headerRoutes.defaultsoutes"/>
        <div class="container py-4">
            <div class="row mb-3">
                <div class="col-12">
                    <Accounts :Agent="Agent" :changeBalanceAccount="getInvoices" />
                </div>
            </div>
            <div class="long-title text-center mb-3">
                <h1 class="page-title">Счета</h1>
            </div>
            <div class="row">
                <div class="col-12">
                    <div v-if="!balanceAccountId">
                        <DxLoadIndicator
                                id="medium-indicator"
                                :height="20"
                                :width="20"
                        />
                        Загрузка
                    </div>
                    <div v-if="balanceAccountId">
                        <DxDataGrid
                                id="gridContainerInvoices"
                                :ref="dataGridRefKey"
                                :data-source="storeInvoices"
                                :columns="columnsInvoices"
                                :show-borders="true"
                                :row-alternation-enabled="true"
                                :show-row-lines="true"
                                :customize-columns="customizeColumnsInvoices"
                                :paging="{pageSize: pageSize, enabled: true}"
                                :pager="pager"
                                :remote-operations="true"
                                :filter-row="{visible: true}"
                                @row-click="onRowClick"
                        >
                            <DxSelection mode="single"/>
                            <DxHeaderFilter
                                    :visible="true"
                            />
                            <DxMasterDetail
                                    :enabled="true"
                                    template="masterDetailTemplate"
                            />
                            <template #number-cell-template="{ data }">
                                <div>
                                    <div v-if="$mq == 'sm' || $mq == 'md' || $mq == 'lg'" v-html="getStatusNumber(data.data.status, data.data.number)"></div>
                                    <div v-if="$mq != 'sm' && $mq != 'md' && $mq != 'lg'">{{ data.data.number }}</div>
                                </div>
                            </template>
                            <template #chart-cell-template="{ data }">
                                <div>
                                    <span v-if="$mq == 'sm' || $mq == 'md' || $mq == 'lg'">{{ data.data.postingAmount | formatMoney2 }}</span>
                                    <span v-if="$mq != 'sm' && $mq != 'md' && $mq != 'lg'" class="tooltip-lable">{{ data.data.postingAmount | formatMoney2 }}</span>
                                    <DxBullet
                                            :color="'#5ebd5e'"
                                            :start-scale-value="0"
                                            :end-scale-value="data.data.amount"
                                            :target="data.data.amount"
                                            :value="data.data.postingAmount"
                                            :show-zero-level="true"
                                            class="bullet"
                                            v-if="$mq != 'sm' && $mq != 'md' && $mq != 'lg'"
                                    >
                                        <DxTooltip content-template="tooltipTemplate"/>
                                        <template #tooltipTemplate="{ data }">
                                            <div>
                                                <b>Сумма счета:</b> {{data.target | formatMoney2}}<br><b class="text-success">Оплаченная сумма:</b> {{data.value | formatMoney2}}<br><b class="text-danger">Не оплаченная сумма:</b> {{(data.target-data.value) | formatMoney2}}
                                            </div>
                                        </template>
                                    </DxBullet>
                                </div>
                            </template>
                            <template #masterDetailTemplate="{ data }">
                                <Invoice
                                        :invoiceId="data.key"
                                        :agent="Agent"
                                        :balanceAccountId="balanceAccountId"
                                />
                            </template>
                        </DxDataGrid>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import headerRoutes from './header-routes';
    import {mapGetters} from 'vuex';
    import {httpClient} from '../../shared/services';
    import {Header, Footer} from '../../shared/components';
    import {DxLoadIndicator} from 'devextreme-vue/load-indicator';
    import { DxDataGrid, DxMasterDetail, DxSelection, DxHeaderFilter } from 'devextreme-vue/data-grid';
    import CustomStore from 'devextreme/data/custom_store';
    import DxBullet, { DxTooltip } from 'devextreme-vue/bullet';
    import moment from 'moment';

    import Accounts from './Accounts';
    import Invoice from './detail/Invoice';

    const dataGridRefKey = "invoices-data-grid";

    export default {
        name: 'FinancesInvoices',
        components: {
            Header,
            Footer,
            DxLoadIndicator,
            Accounts,
            DxDataGrid,
            DxBullet,
            DxTooltip,
            DxMasterDetail,
            DxSelection,
            Invoice,
            DxHeaderFilter
        },
        data() {
            return {
                headerRoutes,
                storeInvoices: null,
                balanceAccountId: "",
                dataGridRefKey,
                selectedRowKey: "",
                pager: {
                    allowedPageSizes: [5, 10, 15, 30],
                    showInfo: true,
                    infoText: 'Страница {0} из {1} (Всего {2})',
                    showNavigationButtons: true,
                    showPageSizeSelector: true,
                    visible: true
                },
                pageSize: 10,
                columnsInvoices: [
                    {
                        caption: 'Номер',
                        dataField: 'number',
                        allowSorting: true,
                        allowFiltering: true,
                        cellTemplate: "number-cell-template",
                        width: 140,
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'Дата',
                        dataField: 'invoiceDate',
                        dataType: 'date',
                        format: 'dd.MM.yyyy',
                        allowSorting: true,
                        width: 140,
                        allowResizing: false,
                        allowFiltering: true,
                        sortIndex: 0,
                        sortOrder: "desc",
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'Описание',
                        dataField: 'description',
                        allowSorting: false,
                        allowFiltering: true,
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'Сумма',
                        dataField: 'amount',
                        dataType: 'number',
                        allowSorting: true,
                        allowFiltering: true,
                        width: 120,
                        customizeText: this.formatMoney2,
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'Оплачено',
                        dataField: 'postingAmount',
                        dataType: 'number',
                        allowSorting: true,
                        encodeHtml: false,
                        allowFiltering: true,
                        cellTemplate: "chart-cell-template",
                        width: 150,
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'Срок оплаты',
                        dataField: 'checkPayingDate',
                        dataType: 'date',
                        format: 'dd.MM.yyyy',
                        allowSorting: true,
                        allowFiltering: true,
                        width: 140,
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'Состояние',
                        dataField: 'status',
                        allowSorting: true,
                        allowFiltering: false,
                        width: 120,
                        encodeHtml: false,
                        customizeText: this.getStatus,
                        allowHeaderFiltering: true,
                        headerFilter: {
                            dataSource: [{
                                text: 'В обработке',
                                value: 'pdsEditing'
                            }, {
                                text: 'Обработан',
                                value: 'pdsAccounted'
                            }, {
                                text: 'Анулирован',
                                value: 'pdsReject'
                            }]
                        }
                    }
                ]
            }
        },
        created() {
            window.addEventListener("resize", this.resizeEventHandler);
        },
        destroyed() {
            window.removeEventListener("resize", this.resizeEventHandler);
        },
        mounted() {

        },
        methods: {
            getInvoices(balanceAccountId) {
                this.balanceAccountId = balanceAccountId;

                this.storeInvoices = new CustomStore({
                    key: 'invoiceId',
                    byKey: async function (key) {
                        if(key) {
                            console.log(key);
                        }
                    },
                    load: (loadOptions) => {

                        let tokenId = this.Agent.tokenId;
                        let sortStr = '';
                        let filterStr = '';
                        let takeStr = '';
                        let skeepStr = '';

                        if(loadOptions.take) {
                            takeStr = `&take=${loadOptions.take}`;
                        }

                        if(loadOptions.skip) {
                            skeepStr = `&skeep=${loadOptions.skip}`;
                        }

                        if (loadOptions.sort) {
                            let sort = loadOptions.sort[0];
                            sortStr = `&order_field=${sort.selector}&order_desc=${sort.desc}`;
                        }

                        if (loadOptions.filter) {

                            if(loadOptions.filter.columnIndex != undefined) { // Фильтрация только по одному полю
                                switch (loadOptions.filter.columnIndex) {
                                    case 1:
                                    case 5:
                                        if (Array.isArray(loadOptions.filter[0])) {
                                            filterStr = this.getFilterGroup(loadOptions.filter);
                                        } else {
                                            let dateFilter = moment(loadOptions.filter[2]).format('YYYY-MM-DDTHH:mm:ss');
                                            filterStr = this.getFilterItem(loadOptions.filter[0], loadOptions.filter[1], dateFilter);
                                        }
                                        break;
                                    default:
                                        if (Array.isArray(loadOptions.filter[0])) {
                                            filterStr = this.getFilterGroup(loadOptions.filter);
                                        } else {
                                            filterStr = this.getFilterItem(loadOptions.filter[0], loadOptions.filter[1], loadOptions.filter[2]);
                                        }
                                        break;
                                }

                                filterStr = filterStr ? `&filter={"and":[${filterStr}]}` : '';
                            } else { // Фильтрация по нескольким полям
                                if(loadOptions.filter[0] != 'offerId') {
                                    if(loadOptions.filter[0] != '!') {
                                        filterStr = this.getFilterGroup(loadOptions.filter);
                                    } else {
                                        if (Array.isArray(loadOptions.filter[1][0])) {
                                            filterStr = this.getFilterGroup(loadOptions.filter[1], true);
                                        } else {
                                            filterStr = this.getFilterItem(loadOptions.filter[1][0], '<>', loadOptions.filter[1][2]);
                                        }
                                    }

                                    filterStr = filterStr ? `&filter=${filterStr}` : '';
                                }
                            }
                        }

                        return httpClient.get(`v2/Finances/${balanceAccountId}/Invoices?tokenId=${tokenId}${takeStr}${skeepStr}${sortStr}${filterStr}`)
                            .then((invoices) => {
                                return {
                                    key: 'invoiceId',
                                    data: invoices.data.items,
                                    totalCount: invoices.data.totals,
                                    summary: invoices.data.totals,
                                    groupCount: invoices.data.count,
                                    userData: invoices.data,
                                };
                            })
                            .catch(() => {
                                throw 'Data Loading Error';
                            });
                    }
                });
            },
            getFilterOp(op) {
                switch (op) {
                    case '>':
                        return 'gt';
                    case '>=':
                        return 'gte';
                    case '<':
                        return 'lt';
                    case '=<':
                    case '<=':
                        return 'lte';
                    case '=':
                        return 'eq';
                    case '<>':
                        return 'neq';
                    case 'contains':
                        return 'con';
                    case 'notcontains':
                        return 'ncon';
                    case 'startswith':
                        return 'sw';
                    case 'endswith':
                        return 'ew';
                }
            },
            getFilterItem(field_name, op, value) {
                if(field_name == 'amount' || field_name == 'postingAmount') {
                    value = `${value}`;
                } else {
                    switch (value) {
                        case true:
                        case false:
                            value = `${value}`;
                            break;
                        default:
                            value = `"${value}"`;
                            break;
                    }
                }

                return `{"${field_name}":{"op":"${this.getFilterOp(op)}","value":${value}}}`;
            },
            getFilterGroup(group_filters, isNo = false) {
                let condition = '';
                let filter_items = '';

                group_filters.forEach(function (filter) {
                    if (Array.isArray(filter)) {
                        if(Array.isArray(filter[0])) {
                            filter_items += this.getFilterGroup(filter) + ',';
                        } else {
                            if(filter[0] == '!') {
                                if(Array.isArray(filter[1][0])) {
                                    filter_items += this.getFilterGroup(filter[1], true) + ',';
                                } else {
                                    filter_items += this.getFilterItem(filter[1][0], '<>', filter[1][2]) + ',';
                                }
                            } else {
                                switch (group_filters.columnIndex) {
                                    case 1:
                                    case 5:
                                        var dateFilter1 = moment(filter[2]).format('YYYY-MM-DDTHH:mm:ss');
                                        filter_items += this.getFilterItem(filter[0], (isNo && filter[1] == '=' ? '<>' : filter[1]), dateFilter1) + ',';
                                        break;
                                    default:
                                        switch (filter.columnIndex) {
                                            case 1:
                                            case 5:
                                                var dateFilter2 = moment(filter[2]).format('YYYY-MM-DDTHH:mm:ss');
                                                filter_items += this.getFilterItem(filter[0], (isNo && filter[1] == '=' ? '<>' : filter[1]), dateFilter2) + ',';
                                                break;
                                            default:
                                                filter_items += this.getFilterItem(filter[0], (isNo && filter[1] == '=' ? '<>' : filter[1]), filter[2]) + ',';
                                                break;
                                        }
                                        break;
                                }
                            }
                        }
                    } else {
                        condition = filter;
                    }
                }, this);

                filter_items = filter_items.replace(/,$/, "");

                return `{"${condition}":[${filter_items}]}`;
            },
            changeColumns(columns) {
                if(columns.length > 0) {
                    columns[0].width = '140px';
                    columns[1].visible = true;
                    columns[2].visible = true;
                    columns[3].visible = true;
                    columns[4].visible = true;
                    columns[5].visible = true;
                    columns[6].visible = true;

                    switch (this.$mq) {
                        case 'sm':
                            columns[0].width = '100%';
                            columns[2].visible = false;
                            columns[3].visible = false;
                            columns[4].visible = false;
                            columns[5].visible = false;
                            columns[6].visible = false;
                            break;
                        case 'md':
                        case 'lg':
                            columns[0].width = '100%';
                            columns[4].visible = false;
                            columns[5].visible = false;
                            columns[6].visible = false;
                            break;
                    }
                }

                return columns;
            },
            customizeColumnsInvoices(columns) { //columns
                this.changeColumns(columns);
            },
            onRowClick(e) {
                if(e.rowType == "data") {
                    if(this.selectedRowKey != e.key) {
                        if(this.selectedRowKey != "") {
                            this.dataGrid.collapseRow(this.selectedRowKey);
                        }

                        this.selectedRowKey = e.key;

                        this.dataGrid.expandRow(this.selectedRowKey);
                    } else {
                        this.dataGrid.collapseRow(this.selectedRowKey);

                        this.selectedRowKey = "";
                    }
                }
            },
            formatMoney2(options) {
                return this.$options.filters.formatMoney2(options.value);
            },
            getStatus(options) {
                switch (options.value) {
                    case 'pdsEditing':
                        return '<span class="badge badge-warning font-12">В обработке</span>';

                    case 'pdsAccounted':
                        return '<span class="badge badge-success font-12">Обработан</span>';

                    case 'pdsReject':
                        return '<span class="badge badge-danger font-12">Анулирован</span>';
                }

                return '';
            },
            getStatusNumber(status, number) {
                switch (status) {
                    case 'pdsEditing':
                        return '<span class="badge badge-warning font-12">'+number+'</span>';

                    case 'pdsAccounted':
                        return '<span class="badge badge-success font-12">'+number+'</span>';

                    case 'pdsReject':
                        return '<span class="badge badge-danger font-12">'+number+'</span>';
                }

                return number;
            },
            resizeEventHandler() { // e
                this.dataGrid.option('columns', this.changeColumns(this.dataGrid.option().columns));
                this.dataGrid.repaint();
            }
        },
        computed: {
            ...mapGetters([
                'isLoading',
                'Agent'
            ]),
            dataGrid: function() {
                return this.$refs[dataGridRefKey].instance;
            }
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .tooltip-lable {
        display: block;
        position: absolute;
        padding-top: 5px;
        margin-left: 20px;
    }
</style>
