<template>
    <div>
        <div v-if="!invoice">
            <DxLoadIndicator
                    id="medium-indicator"
                    :height="20"
                    :width="20"
            />
            Загрузка
        </div>
        <div v-if="invoice" class="table-responsive-md">
            <div class="row select-invoice">
                <div class="col-12 col-md-3 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Номер:
                    </p>
                    <p class="item-text">
                        {{ invoice.number }}
                    </p>
                </div>
                <div class="col-12 col-md-3 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Дата:
                    </p>
                    <p class="item-text">
                        {{ invoice.invoiceDate | moment("DD.MM.YYYY") }}
                    </p>
                </div>
                <div class="col-12 col-md-6 mb-3 col-lg-4 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Договор:
                    </p>
                    <p class="item-text">
                        <button :id="'btn-contract-caption-'+invoice.contractId" type="button" class="btn btn-link btn-sm p-0 text-left" style="line-height: 0;" @click="openContract(invoice.contractId)">{{ invoice.contractCaption }}</button>
                    </p>
                </div>
                <div class="col-12 col-md-4 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Срок оплаты:
                    </p>
                    <p class="item-text" v-if="invoice.checkPayingDate">
                        {{ invoice.checkPayingDate | moment("DD.MM.YYYY") }}
                    </p>
                    <p class="item-text" v-if="!invoice.checkPayingDate">
                        Не указан
                    </p>
                </div>
                <div class="col-12 col-md-4 col-lg-2 mb-3 mb-md-3 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2 mb-0">
                        Состояние:
                    </p>
                    <p class="item-text" v-html="getStatus(invoice.status)"></p>
                </div>
                <div class="col-12 col-md-8 col-lg-6 mb-3 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Первичный документ:
                    </p>
                    <p class="item-text" v-if="invoice.primaryDocumentCaption">
                        <button :id="'btn-primaryDocument-caption-'+invoice.contractId" type="button" class="btn btn-link btn-sm p-0 text-left" style="line-height: 0;" @click="openPrimaryDocument(invoice.primaryDocumentId)">{{ invoice.primaryDocumentCaption }}</button>
                    </p>
                    <p class="item-text" v-if="!invoice.primaryDocumentCaption">
                        -
                    </p>
                </div>
                <div class="col-12 col-md-4 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Сумма:
                    </p>
                    <p class="item-text">
                        {{ invoice.amount | formatMoney2 }}
                    </p>
                </div>
                <div class="col-12 col-md-4 col-lg-2 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Не оплачено:
                    </p>
                    <p class="item-text">
                        {{ invoice.balanceAmount | formatMoney2 }}
                    </p>
                </div>
                <div class="col-12 col-md-4 col-lg-2 mb-3 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2">
                        Оплачено:
                    </p>
                    <p class="item-text">
                        {{ invoice.postingAmount | formatMoney2 }}
                    </p>
                </div>
            </div>
            <div class="mb-3">
                <ul class="nav nav-tabs" :id="'invoiceTab-'+invoice.invoiceId" role="tablist">
                    <li class="nav-item" role="presentation">
                        <a class="nav-link active" :id="'elements-tab-'+invoice.invoiceId" data-toggle="tab" :href="'#elements-'+invoice.invoiceId" role="tab" :aria-controls="'elements-'+invoice.invoiceId" aria-selected="true">Услуги включеные в счет</a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" :id="'basisDocument-tab-'+invoice.invoiceId" data-toggle="tab" :href="'#basisDocument-'+invoice.invoiceId" role="tab" :aria-controls="'basisDocument-'+invoice.invoiceId" aria-selected="false">Документ основания</a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" :id="'postings-tab-'+invoice.invoiceId" data-toggle="tab" :href="'#postings-'+invoice.invoiceId" role="tab" :aria-controls="'postings-'+invoice.invoiceId" aria-selected="false">Проводки по оплате</a>
                    </li>
                </ul>
                <div class="tab-content" :id="'invoiceTabContent-'+invoice.invoiceId">
                    <div class="tab-pane fade show active" :id="'elements-'+invoice.invoiceId" role="tabpanel" :aria-labelledby="'elements-tab-'+invoice.invoiceId">
                        <DxDataGrid
                                id="gridContainerElements"
                                :data-source="invoice.elements"
                                :columns="columnsElements"
                                :show-borders="true"
                                :row-alternation-enabled="true"
                                :show-row-lines="true"
                                :customize-columns="customizeColumnsElements"
                        ></DxDataGrid>
                    </div>
                    <div class="tab-pane fade" :id="'basisDocument-'+invoice.invoiceId" role="tabpanel" :aria-labelledby="'basisDocument-tab-'+invoice.invoiceId">
                        <DxDataGrid
                                id="gridContainerBasisDocument"
                                :data-source="[invoice.basisDocument]"
                                :columns="columnsBasisDocument"
                                :show-borders="true"
                                :row-alternation-enabled="true"
                                :show-row-lines="true"
                                :customize-columns="customizeColumnsBasisDocument"
                        >
                            <template #file-name-template="{ data }">
                                <button v-if="data.data.scanFile" :id="'btn-download-file-'+data.data.scanFile.fileId" type="button" class="btn btn-link btn-sm p-0 text-left" @click="downloadFile(data.data.scanFile.fileId, data.data.scanFile.fileName)">{{ data.data.scanFile.fileName }}</button>
                            </template>
                        </DxDataGrid>
                    </div>
                    <div class="tab-pane fade" :id="'postings-'+invoice.invoiceId" role="tabpanel" :aria-labelledby="'postings-tab-'+invoice.invoiceId">
                        <DxDataGrid
                                id="gridContainerPostings"
                                :data-source="invoice.postings"
                                :columns="columnsPostings"
                                :show-borders="true"
                                :row-alternation-enabled="true"
                                :show-row-lines="true"
                                :customize-columns="customizeColumnsPostings"
                        >
                            <template #paying-caption-template="{ data }">
                                <button :id="'btn-paying-caption-'+data.data.payingId" type="button" class="btn btn-link btn-sm p-0 text-left" @click="openPaying(data.data.payingId)">{{ data.data.payingCaption }}</button>
                            </template>
                        </DxDataGrid>
                    </div>
                </div>
            </div>
            <div class="row select-invoice">
                <div class="col-12 d-flex flex-row flex-md-column justify-content-start">
                    <p class="mr-2" style="margin-bottom: 0.2rem;">
                        Скан-копия документа:
                    </p>
                    <p class="item-text" v-if="invoice.scanFile">
                        <button :id="'btn-download-file-'+invoice.scanFile.fileId" type="button" class="btn btn-link btn-sm p-0 text-left" @click="downloadFile(invoice.scanFile.fileId, invoice.scanFile.fileName)">{{ invoice.scanFile.fileName }}</button>
                    </p>
                    <p class="item-text" v-if="!invoice.scanFile">
                        Нет файла
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import jQuery from 'jquery';
    let $ = jQuery;
    import {httpClient} from '../../../shared/services';
    import localStore from '../store';
    import {DxLoadIndicator} from 'devextreme-vue/load-indicator';
    import { DxDataGrid } from 'devextreme-vue/data-grid';

    export default {
        name: "Invoice",
        props: ['invoiceId', 'Agent', 'balanceAccountId', 'getInvoice'],
        components: {
            DxLoadIndicator,
            DxDataGrid
        },
        data() {
            return {
                invoice: null,
                columnsElements: [
                    {
                        caption: 'ACN',
                        dataField: 'acn',
                        allowSorting: false,
                        width: 130
                    },
                    {
                        caption: 'Дата',
                        dataField: 'elementDate',
                        dataType: 'date',
                        format: 'dd.MM.yyyy',
                        allowSorting: false,
                        width: 90,
                        allowResizing: false
                    },
                    {
                        caption: 'Наименование',
                        dataField: 'serviceName',
                        allowSorting: false
                    },
                    {
                        caption: 'Стоимость',
                        dataField: 'amount',
                        allowSorting: false,
                        width: 130,
                        customizeText: this.formatMoney2
                    }
                ],
                columnsBasisDocument: [
                    {
                        caption: 'Номер',
                        dataField: 'basisNumber',
                        allowSorting: false,
                        width: 150
                    },
                    {
                        caption: 'Дата',
                        dataField: 'basisDate',
                        dataType: 'date',
                        format: 'dd.MM.yyyy',
                        allowSorting: false,
                        width: 90,
                        allowResizing: false
                    },
                    {
                        caption: 'Документ',
                        dataField: 'basisDocumentCaption',
                        allowSorting: false
                    },
                    {
                        caption: 'Файл',
                        dataField: 'scanFile.fileName',
                        cellTemplate: "file-name-template",
                        allowSorting: false
                    },
                ],
                columnsPostings: [
                    {
                        caption: 'Платеж',
                        dataField: 'payingCaption',
                        allowSorting: false,
                        cellTemplate: "paying-caption-template",
                    },
                    {
                        caption: 'Сумма проводки',
                        dataField: 'amount',
                        allowSorting: false,
                        width: 130,
                        customizeText: this.formatMoney2
                    }
                ]
            }
        },
        mounted() {
            localStore.dispatch('getInvoice', {tokenId: this.Agent.tokenId, balanceAccountId: this.balanceAccountId, invoiceId: this.invoiceId}).then(() => {
                this.invoice = localStore.state.invoice;

                if(this.getInvoice) {
                    this.getInvoice(this.invoice);
                }
            })
        },
        methods: {
            customizeColumnsElements(columns) {
                if(columns.length > 0) {
                    if(this.$mq == 'sm' || this.$mq == 'md' || this.$mq == 'lg') {
                        columns[0].hidingPriority = 8;
                        columns[3].hidingPriority = 7;
                    } else {
                        columns[0].hidingPriority = null;
                        columns[1].hidingPriority = null;
                        columns[2].hidingPriority = null;
                        columns[3].hidingPriority = null;
                    }
                }
            },
            customizeColumnsBasisDocument(columns) {
                if(columns.length > 0) {
                    if(this.$mq == 'sm' || this.$mq == 'md' || this.$mq == 'lg') {
                        columns[0].hidingPriority = 2;
                        columns[3].hidingPriority = 1;
                    } else {
                        columns[0].hidingPriority = null;
                        columns[1].hidingPriority = null;
                        columns[2].hidingPriority = null;
                        columns[3].hidingPriority = null;
                    }
                }
            },
            customizeColumnsPostings(columns) {
                if(columns.length > 0) {
                    // if(this.$mq == 'sm' || this.$mq == 'md' || this.$mq == 'lg') {
                    //     columns[0].hidingPriority = 8;
                    //     columns[3].hidingPriority = 7;
                    //     columns[4].hidingPriority = 6;
                    // } else {
                    //     columns[0].hidingPriority = null;
                    //     columns[1].hidingPriority = null;
                    //     columns[2].hidingPriority = null;
                    //     columns[3].hidingPriority = null;
                    //     columns[4].hidingPriority = null;
                    // }
                }
            },
            formatMoney2(options) {
                return this.$options.filters.formatMoney2(options.value);
            },
            downloadFile(fileId, fileName) {
                $('#btn-download-file-'+fileId).html('Загрузка...');
                $('#btn-download-file-'+fileId).prop('disabled', true);

                httpClient.get(`v2/FilesStarage/${fileId}/Download?tokenId=${this.Agent.tokenId}`)
                    .then((file) => {
                        let fileExt = fileName.split('.').pop();

                        const linkSource = `data:application/${fileExt};base64,${file.data}`;
                        const downloadLink = document.createElement('a');
                        document.body.appendChild(downloadLink);

                        downloadLink.href = linkSource;
                        downloadLink.target = '_self';
                        downloadLink.download = fileName;
                        downloadLink.click();

                        downloadLink.remove();

                        $('#btn-download-file-'+fileId).html(fileName);
                        $('#btn-download-file-'+fileId).prop('disabled', false);
                    })
                    .catch(() => {
                        $('#btn-download-file-'+fileId).html(fileName);
                        $('#btn-download-file-'+fileId).prop('disabled', false);
                    });
            },
            getStatus(status) {
                switch (status) {
                    case 'pdsEditing':
                        return '<span class="badge badge-warning font-12">В обработке</span>';

                    case 'pdsAccounted':
                        return '<span class="badge badge-success font-12">Обработан</span>';

                    case 'pdsReject':
                        return '<span class="badge badge-danger font-12">Анулирован</span>';
                }

                return '';
            },
            openContract(contractId) {
                let route = this.$router.resolve({name: 'ProfileContract', params: {contractId: contractId}});
                window.open(route.href, '_blank');
            },
            openPaying(payingId) {
                let route = this.$router.resolve({name: 'FinancesPaying', params: {payingId: payingId}});
                window.open(route.href, '_blank');
            },
            openPrimaryDocument(primaryDocumentId) {
                let route = this.$router.resolve({name: 'PrimaryDocsPrimaryDocument', params: {primaryDocumentId: primaryDocumentId}});
                window.open(route.href, '_blank');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .select-invoice {
        -ms-content-zooming: none;
        -webkit-tap-highlight-color: transparent;
        -webkit-text-size-adjust: none;
        -webkit-touch-callout: none;
        padding: 0;
        outline: 0;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
        color: #212529;
        font-weight: 400;
        font-size: 14px;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        line-height: 1.35715;

        p {
            margin-bottom: 0.5rem;
        }

        .item-text {
            border-style: none none dashed none;
            background: 0 0;
            border-bottom: 1px dashed #ced4da;
            border-radius: 0;
        }
    }
</style>
